// Import React Library
import React from 'react';

// Location Component
const Location = () => {
    return (
        <div id='location-section' className='location'>
            <h2>UBICACIÓN</h2>
            <span className='bg-sky-700'></span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3289.3070746838907!2d-58.51274988477672!3d-34.46973355792805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb03362e65c3f%3A0xfa0d5d6178701f06!2sGral.%20Manuel%20Belgrano%20363%2C%20B1642%20San%20Isidro%2C%20Buenos%20Aires%2C%20Argentina!5e0!3m2!1ses-419!2suy!4v1569174411975!5m2!1ses-419!2suy" width="" height="" frameborder="0"  allowfullscreen=""></iframe>
        </div>
    );
};

// Export Location Component
export default Location;