// Import React Library
import React from 'react';

// Import Components
import NavbarRoute from '../components/NavbarRoute';
import Footer from '../components/Footer';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

// Error Component
const Error = () => {
    return (
        <>
            <NavbarRoute />
            <section className='error-component'>
                <FontAwesomeIcon icon={faCircleExclamation} className='error-icon text-sky-800'/>   
                <h1 className='text-sky-800 uppercase'>{`ERROR 404 NOT FOUND - No se pudo acceder al recurso solicitado.`}</h1>
            </section>
            <Footer />
        </> 
    );
};

// Export Error Component
export default Error;