// Import React Library
import React from 'react';

// React icons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import RingVolumeIcon from '@mui/icons-material/RingVolume';

// Footer Component
const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-row'>
                <div className='footer-col'>
                    <AccessTimeIcon fontSize='large' className='footer-icon'/>            
                    <p>Horario de Atención:</p>
                    <p>08:30 a 21:30hs - Guardias de Traumatología.</p>
                </div>

                <div className='footer-col'>
                    <MeetingRoomIcon fontSize='large' className='footer-icon'/>            
                    <p>Puerta de Ingreso:</p>
                    <p>Belgrano 369 de 06 a 23hs.</p>
                </div>

                <div className='footer-col'>
                    <RingVolumeIcon fontSize='large' className='footer-icon'/>            
                    <p>Telefono:</p>
                    <p>011-4732-8888 / 011-4874-3600</p>
                </div>
            </div>

            <div className='footer-row'>
                © 2019 - Sanatorio San Lucas . Todos los derechos reservados.
            </div>
        </div>
    );
};

// Export Footer Component
export default Footer;