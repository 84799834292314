// Import React Library
import React from 'react';

// Import Components
import NavbarRoute from '../components/NavbarRoute';
import Footer from '../components/Footer';

// Import Images
import hospitalImage from '../images/sanatorio-san-lucas-cover-image-3.jpg';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe , faChalkboardUser } from '@fortawesome/free-solid-svg-icons';


// TeachingCommittee Component
const TeachingCommittee = () => {
    return (
        <>
            <NavbarRoute />
            <div class="teaching-committee-section">

                <div className='teaching-container'>
                    <h1 className='text-sky-800'>Comité de Docencia <FontAwesomeIcon icon={faChalkboardUser} /></h1>
                </div>

                <img src={hospitalImage} alt="Hospital Img" />

                <h3>Curso de Farmacología de Pre-Grado</h3>
                <p>Este curso se lleva a cabo hace varios años y se dicta todos los sábados en el horario de 9 a 12hs, a cargo del Director:  Prof. Dr. Mariano Rodriguez.</p>
                <br />
                <p>Encontrará más información en:  <FontAwesomeIcon icon={faGlobe} /> 
                    <a href="https://farmacologiauba.wordpress.com/" target='_blank' rel="noreferrer"> https://farmacologiauba.wordpress.com/</a>
                </p>
            </div>
            <Footer />
        </>
    );
};

// Export TeachingCommittee Component
export default TeachingCommittee;