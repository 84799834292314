// Import react library.
import * as React from 'react';
import { useState } from 'react';

// Import Images
import logoSanLucas from '../images/sanatorio-san-lucas-white.png';

// Import MUI
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

// Import MUI Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

// Navbar component Open.
const NavbarRoute = () => {
    // Home redirect
    const redirectHome = () => window.location.href;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Mobile Button
    const [navbarButtonStatus , setNavbarButtonStatus] = useState(false);

    // Open Mobile Menu
    const openMenu = () =>{
        if(!navbarButtonStatus){
            document.querySelector(".navbar-menu-mobile").style.width = "100%";
            document.querySelector(".navbar-menu-mobile").style.display = "block";
            setNavbarButtonStatus(true);
        }else{
            document.querySelector(".navbar-menu-mobile").style.width = "0";
            document.querySelector(".navbar-menu-mobile").style.display = "none";
            setNavbarButtonStatus(false);
        }
    }
    
    // Resize screen event
    window.addEventListener('resize', () =>{
        if(window.innerWidth > 1024){
            setNavbarButtonStatus(false);
            document.querySelector(".navbar-menu-mobile").style.display = "none";
        }
    });

    return (
        <nav className='py-9 px-20 bg-sky-800'>

            <Link to='/'>
                <img src={logoSanLucas} alt='Sanatorio San Lucas' onClick={() => redirectHome()}/>
            </Link>

            <ul className='navbar-menu-desktop'>
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='our-navbar-button-route'
                >
                    Nosotros <ArrowDropDownIcon />
                </Button>
            </ul>

            <ul className='navbar-menu-mobile'>
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='our-navbar-button-route'
                >
                    Nosotros <ArrowDropDownIcon />
                </Button>

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={() => {window.location.href = './institutional'}}>Institucional</MenuItem>
                    <MenuItem onClick={() => {window.location.href = './medic-staff'}}>Staff Medico</MenuItem>
                    <MenuItem onClick={() => {window.location.href = './teaching-committee'}}>Comite de docencia</MenuItem>
                </Menu>
            </ul>    

            <FontAwesomeIcon icon={faBars} className='navbar-button' onClick={() => openMenu()}/>
        </nav>
    );
};

// Export Nabvar component
export default NavbarRoute;