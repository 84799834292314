// Import Styles
import './sass/index.scss';

// Import Tailwind CSS
import './styles/tailwind.css';

// Import components
import Loader from './components/Loader';
import SideButtons from './components/SideButtons';
import TopButton from './components/TopButton';
import Navbar from './components/Navbar';
import Cover from './components/Cover';
import Services from './components/Services';
import News from './components/News';
import Marquee from './components/Marquee';
import Location from './components/Location';
import Contact from './components/Contact';
import Footer from './components/Footer';

// App component Open
function App() {
  return (
    <div className="App">
        <Loader />
        <SideButtons />
        <TopButton />
        <Navbar />
        <Cover />
        <Services />
        <News />
        <Marquee />
        <Location />
        <Contact />
        <Footer />
    </div>
  );
}

// Export App component
export default App;
