// Import react library
import React from 'react';

// Import icons
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import CallIcon from '@mui/icons-material/Call';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

// Telephone Button
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// SideButton component
const SideButtons = () => {
    // Dialog State
    const [open, setOpen] = React.useState(false);

    // Dialog Handlers
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <section className='side-buttons'>
            <div onClick={() => handleClickOpen()} className='telephone-icon general-icon-styles bg-sky-700'>
                <CallIcon fontSize='large'/>
            </div>

            <div className='instagram-icon general-icon-styles'>
                <a href='https://www.instagram.com/sanatorio.sanlucas/' target="_blank" rel="noopener noreferrer">
                    <InstagramIcon fontSize='large'/>
                </a>
            </div>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='bootstrap-dialog'
            >
                <DialogTitle className='text-sky-700' sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Contáctenos {' '}
                </DialogTitle>

                <IconButton
                    aria-label="Open"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <Typography className='text-sky-700'>
                        Teléfono
                    </Typography>

                    <br />

                    <Typography gutterBottom>
                        { ' ' } <PhoneAndroidIcon fontSize='small'/> 011 4732 8888 
                    </Typography>

                    <Typography gutterBottom>
                        { ' ' } <PhoneAndroidIcon fontSize='small'/> 011 4874 3600
                    </Typography>

                    <br></br>

                    <Typography className='text-sky-700'>
                        Teléfono de emergencia
                    </Typography>

                    <br />

                    <Typography gutterBottom>
                        { ' ' } <PhoneAndroidIcon fontSize='small'/> 011 4732 1000
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Cerrar
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </section>
    );
};

// Export SideButtons Component
export default SideButtons;