// Import React Library
import React from 'react';

// Import Components
import NavbarRoute from '../components/NavbarRoute';
import Footer from '../components/Footer';

// Import Images
import hospitalImage from '../images/sanatorio-san-lucas-cover-image-3.jpg';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock , faLaptopMedical , faNotesMedical , faUserTie } from '@fortawesome/free-solid-svg-icons';


// Medic Staff Component
const MedicStaff = () => {
    return (
        <>
            <NavbarRoute />
            <div className="medic-staff-section-content">
                <div className='teaching-container'>
                    <h1 className='text-sky-800'>Staff Médico <FontAwesomeIcon icon={faLaptopMedical} /></h1>
                </div>

                <img src={hospitalImage} alt='Medic Staff Img'/>

                <div>
                    <p><strong>UTI – UNIDAD DE TERAPIA INTENSIVA</strong></p>
                    <p>  <FontAwesomeIcon icon={faClock} /> Horario de Visitas: 12:30 a 13:30 Hs // 18:30 a 19:30 Hs</p>
                    <p>  <FontAwesomeIcon icon={faNotesMedical} /> Coordinadores: Dra. Mónica Quinteros y Dr. Hernán Nuñez</p>
                </div>

                <div>
                    <p><strong>UCO – UNIDAD CORONARIA</strong></p>
                    <p> <FontAwesomeIcon icon={faClock} /> Horario de Visitas: 12:30 a 13:30 Hs // 18:30 a 19:30 Hs</p>
                    <p> <FontAwesomeIcon icon={faNotesMedical} /> Coordinador: Dr. Martín Odone</p>
                    <p> <FontAwesomeIcon icon={faUserTie} /> Jefe:  Dr.  Norberto Almirón</p>
                </div>

                <div>
                    <p><strong>UPEDIATRIA</strong></p>
                    <p> <FontAwesomeIcon icon={faClock} /> Horario de Visitas: Lu a Lu de 10 a 12 Hs // 16 a 20 Hs</p>
                    <p> <FontAwesomeIcon icon={faNotesMedical} /> Coordinadora: Dra. Luciana Ruiz García</p>
                    <p> <FontAwesomeIcon icon={faUserTie} /> Jefe: Dr. Jorge Buraschi</p>
                </div>

                <div>
                    <p><strong>NEONATOLOGÍA</strong></p>
                    <p> <FontAwesomeIcon icon={faUserTie} /> Jefe: Dr. Gonzalez Bernaldo Quiróz</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

// Export MedicStaff Component
export default MedicStaff;