// Import React Lbrary
import * as React from 'react';

// Import Images
import martinOmarLogo  from '../images/centro-medico-omar.png';
import centraLabLogo  from '../images/centra-lab.svg';
import argusLogo  from '../images/argus.png';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faTruckMedical, faUserDoctor, faBedPulse, faHeartPulse, faFileWaveform } from '@fortawesome/free-solid-svg-icons';

// Import react reveal animation
import { Slide, Bounce} from "react-awesome-reveal";

// Component :)
const Services = () => { 
    return (
        <section id='services-section' className='our-services-section'>
            <h2>Servicios</h2>
            <span className='bg-sky-700'></span>
    
            <div className='our-services'>
                <Slide>
                    <div className='service'>
                        <FontAwesomeIcon icon={faHospital} className='service-icon' />
                        <p className='service-title'>CAI</p>
                        <p className='service-sub-title'>Centro de Atención Inmediata</p>
                    </div>
    
                    <div className='service'>
                        <FontAwesomeIcon icon={faTruckMedical} className='service-icon' />
                        <p className='service-title'>GUARDIA</p>
                        <p className='service-sub-title'>Guardia</p>
                    </div>
    
                    <div className='service'>
                        <FontAwesomeIcon icon={faUserDoctor} className='service-icon' />
                        <p className='service-title'>PEDIATRIA</p>
                        <p className='service-sub-title'>Pediatria</p>
                    </div>
                </Slide>
    
                <Slide direction='right'>
                    <div className='service'>
                        <FontAwesomeIcon icon={faFileWaveform} className='service-icon' />
                        <p className='service-title'>UTI</p>
                        <p className='service-sub-title'>Unidad de Terapía Intensiva</p>
                    </div>
    
                    <div className='service'>
                        <FontAwesomeIcon icon={faHeartPulse} className='service-icon' />
                        <p className='service-title'>UCO</p>
                        <p className='service-sub-title'>Unidad Coronaría</p>
                    </div>
    
                    <div className='service'>
                        <FontAwesomeIcon icon={faBedPulse} className='service-icon' />
                        <p className='service-title'>INTERNACIÓN</p>
                        <p className='service-sub-title'>Internación</p>
                    </div>
                </Slide>
            </div>

            <div className='our-services-logos'>
                <Bounce duration={3000}>
                    <a href='https://www.cmmyo.com.ar/'  target="_blank" rel="noreferrer">
                        <img src={martinOmarLogo} alt='Martin y Omar'/>
                    </a>
                    
                    <a href='https://www.centralab.com.ar' target='_blank' rel="noreferrer">
                        <img src={centraLabLogo} alt='Centra Lab'/>
                    </a>

                    <a href='https://www.argus.com.ar/' target='_blank' rel="noreferrer">
                        <img src={argusLogo} alt='Argus'/>
                    </a>
                </Bounce>
            </div>
        </section>
      );
}

// Export SideButtons Component
export default Services;