// Import react library
import React from 'react';

// Import react DOM
import ReactDOM from 'react-dom/client';

// Import components
import App from './App';
import Institutional from './routes/Institutional';
import MedicStaff from './routes/MedicStaff';
import TeachingCommittee from './routes/TeachingCommittee';
import Error from './routes/Error';


// Import Routing System
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Router Component
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement : <Error />
  },
  {
    path: '/institutional',
    element: <Institutional />
  },
  {
    path: '/medic-staff',
    element: <MedicStaff />
  },
  {
    path: '/teaching-committee',
    element: <TeachingCommittee />
  }
]);

// App web root
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);