// Import react library
import React , { useState } from 'react';

// Loader component
const Loader = () => {

    // Loader State
    const [loaderState , setLoaderState] = useState(true);
    
    setTimeout(()=> {
      setLoaderState(false);
      document.querySelector('body').style.overflowY = "scroll";
    }, 4000);

    return (
        <div className={loaderState ? 'loader-section' : ''}>
            <div class="loader"></div>
        </div>
    );
};

// Export Loader component
export default Loader;