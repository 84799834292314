// Import react library.
import * as React from 'react';
import { useState } from 'react';

// Import Images
import logoSanLucas from '../images/sanatorio-san-lucas-sky-blue.png';

// Import MUI
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

// Import MUI Icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';

// Navbar component Open.
const Navbar = () => {
    // Home redirect
    const redirectHome = () => window.location.href;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Mobile Button
    const [navbarButtonStatus , setNavbarButtonStatus] = useState(false);

    // Open Mobile Menu
    const openMenu = () =>{
        if(!navbarButtonStatus){
            document.querySelector(".navbar-menu-mobile").style.width = "100%";
            document.querySelector(".navbar-menu-mobile").style.display = "block";
            setNavbarButtonStatus(true);
        }else{
            document.querySelector(".navbar-menu-mobile").style.width = "0";
            document.querySelector(".navbar-menu-mobile").style.display = "none";
            setNavbarButtonStatus(false);
        }
    }
    
    // Resize screen event
    window.addEventListener('resize', () =>{
        if(window.innerWidth > 1024){
            setNavbarButtonStatus(false);
            document.querySelector(".navbar-menu-mobile").style.display = "none";
        }
    });

    return (
        <nav className='py-9 px-20'>

            <Link to='/'>
                <img src={logoSanLucas} alt='Sanatorio San Lucas' onClick={() => redirectHome()}/>
            </Link>

            <ul className='navbar-menu-desktop'>
                <a href='#services-section'>
                    <li class="text-sky-600">Servicios</li>
                </a>

                <a href='#news-section'>
                    <li class="text-sky-600">Novedades</li>
                </a>

                <a href='#location-section'> 
                    <li class="text-sky-600">Ubicacion</li>
                </a>

                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='our-navbar-button'
                >
                    Nosotros <ArrowDropDownIcon />
                </Button>

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={handleClose}>Institucional</MenuItem>
                    <MenuItem onClick={handleClose}>Staff Medico</MenuItem>
                    <MenuItem onClick={handleClose}>Comite de docencia</MenuItem>
                </Menu>

                <a href="#contact-form">
                    <button class="bg-sky-700 group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md bg-neutral-950 px-6 font-medium text-neutral-200 duration-500">
                        <div class="translate-x-0 opacity-100 transition group-hover:-translate-x-[150%] group-hover:opacity-0 uppercase">
                            Contacto
                        </div>
                        <div class="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    </button>
                </a>
            </ul>

            <ul className='navbar-menu-mobile'>
                <a href='#services-section'>
                    <li class="text-sky-600">Servicios</li>
                </a>

                <a href='#news-section'>
                    <li class="text-sky-600">Novedades</li>
                </a>

                <a href='#location-section'> 
                    <li class="text-sky-600">Ubicacion</li>
                </a>

                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    className='our-navbar-button'
                >
                    Nosotros <ArrowDropDownIcon />
                </Button>

                <Menu
                    id="fade-menu"
                    MenuListProps={{
                    'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={() => {window.location.href = './institutional'}}>Institucional</MenuItem>
                    <MenuItem onClick={() => {window.location.href = './medic-staff'}}>Staff Medico</MenuItem>
                    <MenuItem onClick={() => {window.location.href = './teaching-committee'}}>Comite de docencia</MenuItem>
                </Menu>

                <a href="#contact-form">
                    <button class="bg-sky-700 group relative inline-flex h-12 items-center justify-center overflow-hidden rounded-md bg-neutral-950 px-6 font-medium text-neutral-200 duration-500">
                        <div class="translate-x-0 opacity-100 transition group-hover:-translate-x-[150%] group-hover:opacity-0 uppercase">
                            Contacto
                        </div>
                        <div class="absolute translate-x-[150%] opacity-0 transition group-hover:translate-x-0 group-hover:opacity-100">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6">
                                <path d="M8.14645 3.14645C8.34171 2.95118 8.65829 2.95118 8.85355 3.14645L12.8536 7.14645C13.0488 7.34171 13.0488 7.65829 12.8536 7.85355L8.85355 11.8536C8.65829 12.0488 8.34171 12.0488 8.14645 11.8536C7.95118 11.6583 7.95118 11.3417 8.14645 11.1464L11.2929 8H2.5C2.22386 8 2 7.77614 2 7.5C2 7.22386 2.22386 7 2.5 7H11.2929L8.14645 3.85355C7.95118 3.65829 7.95118 3.34171 8.14645 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    </button>
                </a>
            </ul>    

            <FontAwesomeIcon icon={faBars} className='navbar-button' onClick={() => openMenu()}/>
        </nav>
    );
};

// Export Nabvar component
export default Navbar;