// Import React Library
import React from 'react';

// Import Images
import instalacionesUno from '../images/sanatorio-san-lucas-instalaciones.jpg';
import instalacionesDos from '../images/sanatorio-san-lucas-instalaciones-2.jpg';
import instalacionesTres from '../images/sanatorio-san-lucas-instalaciones-3.jpg';
import instalacionesCuatro from '../images/sanatorio-san-lucas-instalaciones-4.jpg';
import instalacionesCinco from '../images/sanatorio-san-lucas-instalaciones-5.jpg';

// Marquee Component
const Marquee = () => {
    return (
        <div class="marquee">
            <div class="marquee-content">
                <img src={instalacionesUno} alt="Instalaciones Sanatorio" />
                <img src={instalacionesDos} alt="Instalaciones Sanatorio" />
                <img src={instalacionesTres} alt="Instalaciones Sanatorio" />
                <img src={instalacionesCuatro} alt="Instalaciones Sanatorio" />
                <img src={instalacionesCinco} alt="Instalaciones Sanatorio" />
            </div>
        </div>
    );
};

// Export Marquee Component
export default Marquee;