// Import react library
import React from 'react';

// Import React reveal 
import { Slide } from "react-awesome-reveal";


// Cover component
const Cover = () => {
    return (
        <section className='cover-section'>
            <h1 className='text-slate-50 text-8xl text-center md:w-2/3 mx-auto'>
                Sanatorio {''}
                <span className='bg-sky-900 p-3 font-bold'>San Lucas</span>
            </h1>
        </section>
    );
};

// Export Cover component
export default Cover;