// Import React Library
import React, {useState} from 'react';

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretUp } from '@fortawesome/free-solid-svg-icons';

// Top Button Component
const TopButton = () => {

    // Show button state
    const [showButton , setShowButton] = useState(false);

    window.addEventListener("scroll", function() {
        // Verificamos si hemos desplazado 400px o más
        if (window.scrollY >= 400) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    });

    const goToTop = () =>{
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setShowButton(false);
    }

    return (
        <div>
            { 
                showButton ?  
                    <div className='top-button bg-sky-700' onClick={() => goToTop()}> 
                        <FontAwesomeIcon icon={faCaretUp} /> 
                    </div>  : '' 
            }
        </div>
    );
};

// Export TopButton Component
export default TopButton;