// Import React Library
import React from 'react';

// Import Components
import NavbarRoute from '../components/NavbarRoute';
import Footer from '../components/Footer';

// Import images
import hospitalImage from '../images/sanatorio-san-lucas-cover-image-2.jpg'

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion , faClock , faUsers , faUser} from '@fortawesome/free-solid-svg-icons';

// Institutional Component
const Institutional = () => {
    return (
        <>
            <NavbarRoute />
            <div className='institutional-wrapper'>
                <div className='institutional-container'>
                    <h1 className='text-sky-800'>Quienes somos <FontAwesomeIcon icon={faCircleQuestion} /></h1>
                </div>

                <img src={hospitalImage} className='institutional-image' alt='Informes'/>

                <div className='institutional-container'>
                    <p>El Sanatorio San Lucas, es una institución médica polivalente, que desde sus inicios tuvo como premisa apuntar a la máxima complejidad médica, acompañada de afecto, obteniendo como resultado el compromiso diario de  brindar la mejor calidad médica y calidad humana.</p>
                    <p>A través de todos sus servicios, y en una constante actualización edilicia, equipamiento, aparatología y un grupo humano con vocación de servicio, buscamos ser un referente de excelencia.</p>
                    <p>Nuestro objetivo es la asistencia médica y la docencia, que permite una educación médica continua, garantizando el conocimiento del avance de la medicina y la incorporación de nuevas técnicas terapéuticas. El trabajo en equipo, es un objetivo constante por parte de toda la organización, lo cual facilita ofrecer una atención personalizada y una asistencia digna.</p>
                    <br/>
                    <p><strong>PROPÓSITO:</strong></p>
                    <p>Brindar a la comunidad de San Isidro un servicio de salud de calidad.</p>
                    <br />
                    <p><strong>MISIÓN:</strong></p>
                    <p>Cuidar, promover y recuperar la salud de la comunidad sanisidrense,  asimismo ofrecer a nuestro personal y profesionales un ámbito de desarrollo atractivo que favorezca su compromiso y sentido de pertenencia con la Institución. Garantizando la revisión y actualización de conocimientos, procesos, tecnologías y estructuras, gestionando nuestros recursos con racionalidad económica en forma transparente y honesta.</p>
                    <br />
                    <p><strong>VISIÓN:</strong></p>
                    <p>Promover y restaurar la salud, considerando al ser humano como un todo. Brindando atención de calidad, en los diferentes momentos.</p>
                </div>

                <div className='institutional-container'>
                    <h1 className='text-sky-800'>Historía <FontAwesomeIcon icon={faClock} /></h1>
                </div>

                <div className='institutional-container'>
                    <p>
                        El 14 de Diciembre de 1958 y con la presencia de destacadas personalidades y vecinos, entre los cuales se encontraba el ex Intendente de San Isidro Dr. Melchor A. Posse y Monseñor Aguirre, primer Obispo de San Isidro, se funda el Sanatorio San Lucas, en el predio situado en la calle Belgrano 363 de San Isidro.
                        El primer Directorio quedó compuesto por el Dr. Horacio Mónaco, el Sr. José M Mollet, el Dr. Carlos Picasso Cazón, el Sr. Carlos Stabile, el Dr. Oris de Roa, el Dr. J. García Fernandez y el Sr. Santiago Tilmant, asumiendo el Dr Mónaco la presidencia del mismo. La construcción del Sanatorio se inició con un proyecto de la empresa Salas Billoch, ejecutado por la constructora Ganzabal y Cia.
                        En 1965 se termina la obra del 2° piso, se incorporan a la estructura, 12 camas de internación y dos salas de cirugía. A todo esto estaba ya en funcionamiento la primera Guardia Médica compuesta por el Dr. Gustavo Piantoni, hoy distinguido profesional, profesor Emérito de Oftalmología, el Dr. Luis Mazzini, conocido y destacado médico tocoginecológico, el Dr. L. Munne, hoy especialista en Nutrición, el Dr. Alejandro Castro Almeyra, El Dr.Pablo Piva (pediatra muy apreciado en la zona,ler Jefe del Servicio de Pediatría del SSl, fallecido muy joven) y el Dr. Francisco Dondo Lascano, apreciado médico clínico de prolífica descendencia.
                        En 1970 de acuerdo a los avances y criterios modernos de atención, se crea el área de terapia intensiva, a cargo de los Dres Calatayud y Jiménez de Paz. Posteriormente se completó la estructura de quirófano en el 4° piso y habitaciones de internación del 1° al 3° piso.
                    </p>
                    <br />
                    <p>
                        Bajo la gestión del Dr. Hugo de Rosa, se dio a la apertura del S.I.S (San Isidro Salud). Ya en 1985, siguiendo en su mandato, se incorpora la Unidad de Internación Pediátrica, de la mano del Dr. Pablo Piva. Se da comienzo a la Unidad de Coronaria como así la llegada del Primer Tomógrafo en el Departamento de Imágenes. En el que después llegaría el Primer Resonador abierto de la Argentina.
                        Cabe destacar, también, que en su gestión se incorpora el Departamento de Hemodinamia junto al Dr. Magariños.
                    </p>
                    <br />
                    <p>
                        En junio de 1985 ingresa a la institución el Dr Pedro Lawson, como Jefe del Departamento de Pediatría, quien posteriormente, en enero de 1986, asume como Director Médico del Sanatorio, cargo que ocupa hasta la actualidad.
                        Los sucesivos embates de las políticas económicas fueron enfrentados con enorme responsabilidad por los directivos, respondiendo adecuadamente a las distintas situaciones que nuestro volátil entorno político – económico producían, siempre intentando salvaguardar la estructura sanatorial y su compromiso con la comunidad.
                        A partir de 1986 se inicia una etapa de expansión, adquiriendo los predios ubicados sobre la calle Martin y Omar, construyendo un Auditorio para 80 personas al que se bautizó con el nombre del fallecido Dr Luis A Fogo, en un justo y merecido homenaje, auditorio en el que se realizan ateneos, se desarrollan cursos para profesionales y charlas de educación para la salud dirigidas a la comunidad. También se lo facilita a Instituciones de la zona para conferencias y capacitación.
                        Hoy, a los más de60 años de su fundación, aquella “salita” con consultorios externos con que se inició el Sanatorio San Lucas, logró transformarse en el SANATORIO decano y líder de la zona.
                        Los sucesivos cambios en sus autoridades y los distintos accionistas, supieron siempre corregir las desviaciones del rumbo y sostener los principios sobre los cuales fue fundado: “facilitar la atención adecuada, cómoda, capacitada e inmediata de los múltiples problemas relacionados a la salud y que afectan a la familia”. Esta ha sido siempre la principal preocupación de sus autoridades.
                        Hoy el Sanatorio San Lucas cuenta con modernos servicios, dirigidos por profesionales de excelente preparación y experiencia.
                    </p>
                </div>

                <div className='institutional-container'>
                    <h1 className='text-sky-800'>Directores <FontAwesomeIcon icon={faUsers} /></h1>
                </div>

                <div className='institutional-container director-container'>
                    <p><FontAwesomeIcon icon={faUser} /> Director Médico: Dr. Pedro Lawson</p>
                    <br />
                    <p><FontAwesomeIcon icon={faUser} /> Sub-Director Médico: Dr. Martín Duhalde</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

// Export Institutional Component
export default Institutional;